.experience {
  margin-top: 230px;
  width: 100%;
}

.experienceList {
  margin-top: 54px;
}

.education {
  margin-top: 146px;
  width: 100%;
}

.educationList {
  margin-top: 54px;
}

@media (max-width: 1280px) {
  .experience {
    margin-top: 180px;
  }

  .experienceList {
    margin-top: 54px;
  }

  .education {
    margin-top: 148px;
  }

  .educationList {
    margin-top: 54px;
  }
}

@media (max-width: 960px) {
  .experience {
    margin-top: 100px;
  }

  .experienceList {
    margin-top: 46px;
  }

  .education {
    margin-top: 100px;
  }

  .educationList {
    margin-top: 48px;
  }
}

@media (max-width: 640px) {
  .experience {
    margin-top: 100px;
  }

  .experienceList {
    margin-top: 26px;
  }

  .education {
    margin-top: 100px;
  }

  .educationList {
    margin-top: 26px;
  }
}