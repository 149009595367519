.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-weight: 700;
}

@media (max-width: 1280px) {
  .container {
    gap: 7px;
  }
}

@media (max-width: 960px) {
  .container {
    gap: 4px;
  }
}

@media (max-width: 640px) {
  .container {
    gap: 6px;
  }
}
