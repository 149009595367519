.customerJourneyMap {
  margin-top: 0;
  margin-bottom: 130px;
}

.customerJourneyMap h1 {
  margin-top: 0;
  margin-bottom: 24px;
}

.customerJourneyMapImage {
  width: 100%;
  margin-top: 72px;
}

@media (max-width: 1280px) {
  .customerJourneyMap {
    margin-bottom: 110px;
  }

  .customerJourneyMap h1 {
    margin-bottom: 24px;
  }

  .customerJourneyMapImage {
    margin-top: 56px;
  }
}

@media (max-width: 960px) {
  .customerJourneyMap {
    margin-bottom: 96px;
  }

  .customerJourneyMap h1 {
    margin-bottom: 20px;
  }

  .customerJourneyMapImage {
    margin-top: 46px;
  }
}

@media (max-width: 640px) {
  .customerJourneyMap {
    margin-bottom: 64px;
  }

  .customerJourneyMap h1 {
    margin-bottom: 14px;
  }

  .customerJourneyMapImage {
    margin-top: 23px;
  }
}
