.screenTransition {
  z-index: 100;
  position: fixed;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: black;
  width: 100%;
  height: 0;
  bottom: 0;
}