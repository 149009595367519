.competitorAnalysis {
  margin-top: 90px;
  margin-bottom: 130px;
}

.competitorAnalysis h1 {
  margin-top: 0;
  margin-bottom: 24px;
}

.competitorAnalysisImage {
  width: 100%;
  margin-top: 72px;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
}

@media (max-width: 1280px) {
  .competitorAnalysis {
    margin-top: 86px;
    margin-bottom: 110px;
  }

  .competitorAnalysis h1 {
    margin-bottom: 24px;
  }

  .competitorAnalysisImage {
    margin-top: 56px;
  }
}

@media (max-width: 960px) {
  .competitorAnalysis {
    margin-top: 76px;
    margin-bottom: 96px;
  }

  .competitorAnalysis h1 {
    margin-bottom: 20px;
  }

  .competitorAnalysisImage {
    margin-top: 46px;
  }
}

@media (max-width: 640px) {
  .competitorAnalysis {
    margin-top: 56px;
    margin-bottom: 64px;
  }

  .competitorAnalysis h1 {
    margin-bottom: 14px;
  }

  .competitorAnalysisImage {
    margin-top: 23px;
  }
}
