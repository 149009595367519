.uklon {
  margin-top: 226px;
}

.eventzone {
  margin-top: 56px;
}

@media (max-width: 1280px) {
  .uklon {
    margin-top: 130px;
  }

  .eventzone {
    margin-top: 46px;
  }
}

@media (max-width: 960px) {
  .uklon {
    margin-top: 140px;
  }

  .eventzone {
    margin-top: 38px;
  }
}

@media (max-width: 767px) {
  .eventzone {
    margin-top: 120px;
  }
}

@media (max-width: 640px) {
  .uklon {
    margin-top: 100px;
  }

  .eventzone {
    margin-top: 100px;
  }
}