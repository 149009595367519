.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-top: 54px;
  margin-bottom: 54px;
}

.period {
  flex: 1;

  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;
}

.descriptionContainer {
  flex: 2;

  display: flex;
  flex-direction: column;
  gap: 14px;
}

.title {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
}

.description {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;

  color: #6A6A6A;
}

@media (max-width: 1280px) {
  .container {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .period {
    font-size: 20px;
    line-height: 32px;
  }

  .title {
    font-size: 20px;
    line-height: 30px;
  }

  .description {
    font-size: 16px;
    line-height: 23px;
  }
}

@media (max-width: 960px) {
  .container {
    margin-top: 42px;
    margin-bottom: 42px;
  }

  .period {
    font-size: 18px;
    line-height: 27px;
  }

  .title {
    font-size: 18px;
    line-height: 27px;
  }

  .description {
    font-size: 16px;
    line-height: 23px;
  }
}

@media (max-width: 640px) {
  .container {
    margin-top: 40px;
    margin-bottom: 40px;

    flex-direction: column;
    align-items: flex-start;
  }

  .period {
    font-size: 16px;
    line-height: 23px;
  }

  .title {
    font-size: 16px;
    line-height: 23px;
  }

  .description {
    font-size: 14px;
    line-height: 18px;
  }
}