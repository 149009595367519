.results {
  margin-top: 130px;
}

.results h1 {
  margin-top: 0;
  margin-bottom: 24px;
}

@media (max-width: 1280px) {
  .results {
    margin-top: 110px;
  }

  .results h1 {
    margin-bottom: 24px;
  }
}

@media (max-width: 960px) {
  .results {
    margin-top: 96px;
  }

  .results h1 {
    margin-bottom: 20px;
  }
}

@media (max-width: 640px) {
  .results {
    margin-top: 64px;
  }

  .results h1 {
    margin-bottom: 16px;
  }
}