.foundedProblemsContainer {
  background: #f8f8f8;
  margin: 0;
}

.foundedProblems {
  margin-top: 60px;
  margin-bottom: 90px;
}

.foundedProblems h1 {
  margin-top: 0;
  margin-bottom: 24px;
}

.foundedProblemsList {
  margin-top: 100px;
}

.foundedProblemsList ul {
  display: flex;
  flex-wrap: wrap;
  column-gap: 120px;
  row-gap: 63px;

  margin-block-start: 0;
  margin-block-end: 0;
}

.foundedProblemsList ul h3 {
  font-family: "Space Grotesk", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;
  color: #000000;

  margin-top: 0;
  margin-bottom: 0;
}

.foundedProblemsList ul span {
  font-family: "Space Grotesk", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #6A6A6A;
}

.foundedProblemsList ul li {
  width: 293px;
  position: relative;
  list-style-type: none;
}

li:before {
  content: "";
  position: absolute;
  top: 11px;
  left: -22px;
  width: 13px;
  height: 13px;
  background-image: url('./Ellipse.png');
}

@media (max-width: 1280px) {
  .foundedProblems {
    margin-top: 38px;
    margin-bottom: 56px;
  }

  .foundedProblems h1 {
    margin-bottom: 24px;
  }

  .foundedProblemsList {
    margin-top: 72px;
  }

  .foundedProblemsList ul {
    column-gap: 48px;
    row-gap: 66px;
  }

  .foundedProblemsList ul li {
    width: 28%;
  }

  .foundedProblemsList ul h3 {
    font-size: 20px;
  }

  .foundedProblemsList ul span {
    font-size: 16px;
    line-height: 23px;
  }
}

@media (max-width: 960px) {
  .foundedProblems {
    margin-top: 46px;
    margin-bottom: 56px;
  }

  .foundedProblems h1 {
    margin-bottom: 20px;
  }

  .foundedProblemsList {
    margin-top: 64px;
  }

  .foundedProblemsList ul {
    column-gap: 31px;
    row-gap: 40px;
  }

  .foundedProblemsList ul li {
    width: 28%;
  }

  .foundedProblemsList ul h3 {
    font-size: 20px;
  }

  .foundedProblemsList ul span {
    font-size: 16px;
    line-height: 23px;
  }
}

@media (max-width: 640px) {
  .foundedProblems {
    margin-top: 24px;
    margin-bottom: 30px;
  }

  .foundedProblems h1 {
    margin-bottom: 16px;
  }

  .foundedProblemsList {
    margin-top: 55px;
  }

  .foundedProblemsList ul {
    column-gap: 32px;
    row-gap: 32px;
  }

  .foundedProblemsList ul li {
    width: 40%;
  }

  .foundedProblemsList ul h3 {
    font-size: 16px;
  }

  .foundedProblemsList ul span {
    font-size: 14px;
    line-height: 19px;
  }
}