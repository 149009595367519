.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 152px;

  width: 100%;

  margin-top: 200px;
  margin-bottom: 48px;

  position: relative;
}

.footerSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footerSectionTitle {
  color: #6A6A6A;
}

.footerLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 20px;
}

.footerLink {
  color: #000000;
  text-decoration: none;
}

.footerLinkDivider {

}

.copyToClipboard {
  font-size: 15px;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.4);

  position: absolute;
  opacity: 0;
  z-index: -1;
  top: 60px;

  transition: all 0.5s;
}

.success {
  opacity: 1;
  top: 75px;
}

@media (max-width: 1280px) {
  .footer {
    gap: 147px;

    margin-top: 152px;
    margin-bottom: 42px;
  }

  .footerSection {
    gap: 16px;
  }

  .footerLinks {
    gap: 11px;
  }

  .copyToClipboard {
    font-size: 14px;
    line-height: 19px;
  }

  .success {
    top: 70px;
  }
}

@media (max-width: 960px) {
  .footer {
    justify-content: space-between;
    align-items: self-start;

    margin-top: 120px;
    margin-bottom: 40px;

    gap: 15px;
  }

  .footerSection {
    gap: 12px;
  }

  .footerLinks {
    gap: 11px;
  }

  .copyToClipboard {
    font-size: 12px;
    line-height: 16px;

    top: 40px;
  }

  .success {
    top: 65px;
  }
}

@media (max-width: 640px) {
  .footer {
    justify-content: unset;
    align-items: flex-start;
    gap: 66px;

    margin-top: 110px;
    margin-bottom: 20px;
  }

  .footerSection {
    gap: 18px;
  }

  .footerLinks {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .footerLinkDivider {
    display: none;
  }

  .copyToClipboard {
    font-size: 11px;
    line-height: 15px;

    top: 40px;
  }

  .success {
    top: 65px;
  }
}