.projectOverview {
  margin-top: 120px;
  margin-bottom: 120px;
  display: flex;

  gap: 123px;
}

.projectOverview > div {
  margin-top: 5px;
  flex: 1;
}

.projectOverview :nth-child(2) {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (max-width: 1280px) {
  .projectOverview {
    flex-direction: column;
    gap: 20px;

    margin-top: 96px;
    margin-bottom: 96px;
  }
}

@media (max-width: 960px) {
  .projectOverview {
    flex-direction: column;
    gap: 24px;

    margin-top: 110px;
    margin-bottom: 110px;
  }
}

@media (max-width: 640px) {
  .projectOverview {
    flex-direction: column;
    gap: 14px;

    margin-top: 64px;
    margin-bottom: 64px;
  }
}
