.customerProfile {
  display: flex;
  flex-direction: column;

  margin-top: 0;
  margin-bottom: 130px;
}

.customerProfile h1 {
  margin-top: 0;
  margin-bottom: 24px;
}

.customerProfileImage {
  align-self: center;
  width: 50%;
  margin-top: 72px;
}

@media (max-width: 1280px) {
  .customerProfile {
    margin-bottom: 110px;
  }

  .customerProfile h1 {
    margin-bottom: 24px;
  }

  .customerProfileImage {
    margin-top: 56px;
  }
}

@media (max-width: 960px) {
  .customerProfile {
    margin-bottom: 96px;
  }

  .customerProfile h1 {
    margin-bottom: 20px;
  }

  .customerProfileImage {
    margin-top: 46px;
  }
}

@media (max-width: 640px) {
  .customerProfile {
    margin-bottom: 64px;
  }

  .customerProfile h1 {
    margin-bottom: 14px;
  }

  .customerProfileImage {
    width: 80%;
    margin-top: 23px;
  }
}

