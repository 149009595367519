.ideation {
  margin-top: 90px;
}

.ideation h1 {
  margin-top: 0;
  margin-bottom: 24px;
}

.ideationImage {
  width: 100%;
  margin-top: 72px;
}

@media (max-width: 1280px) {
  .ideation {
    margin-top: 86px;
  }

  .ideation h1 {
    margin-bottom: 24px;
  }

  .ideationImage {
    margin-top: 56px;
  }
}

@media (max-width: 960px) {
  .ideation {
    margin-top: 76px;
  }

  .ideation h1 {
    margin-bottom: 20px;
  }

  .ideationImage {
    margin-top: 46px;
  }
}

@media (max-width: 640px) {
  .ideation {
    margin-top: 56px;
  }

  .ideation h1 {
    margin-bottom: 14px;
  }

  .ideationImage {
    margin-top: 23px;
  }
}