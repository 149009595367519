.userResearchContainer {
  background: #f8f8f8;
  margin: 0;
}

.userResearch {
  margin-top: 60px;
  margin-bottom: 82px;
}

.userResearch h1 {
  margin-top: 0;
  margin-bottom: 22px;
}

.userInterviewsImage {
  width: 100%;
  margin-top: 72px;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
}

@media (max-width: 1280px) {
  .userResearch {
    margin-top: 38px;
    margin-bottom: 56px;
  }

  .userResearch h1 {
    margin-bottom: 24px;
  }

  .userInterviewsImage {
    margin-top: 56px;
  }
}

@media (max-width: 960px) {
  .userResearch {
    margin-top: 46px;
    margin-bottom: 64px;
  }

  .userResearch h1 {
    margin-bottom: 20px;
  }

  .userInterviewsImage {
    margin-top: 46px;
  }
}

@media (max-width: 640px) {
  .userResearch {
    margin-top: 24px;
    margin-bottom: 31px;
  }

  .userResearch h1 {
    margin-bottom: 14px;
  }

  .userInterviewsImage {
    margin-top: 26px;
  }
}
