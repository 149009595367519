.bannerBackground {
  background: #B4C4F5;
}

.bannerContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.bannerDescription {
  display: flex;
  flex-direction: column;
  margin: 158px 0 168px;
}

.projectDetailItems {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 48px;
}

.projectBannerImageContainer {
  display: flex;
  align-items: flex-end;
}

.projectBannerImage {
  width: 650px;
}

@media (max-width: 1280px) {
  .bannerDescription {
    margin: 137px 0;
  }

  .projectDetailItems {
    gap: 32px;
    margin-top: 46px;
  }

  .projectBannerImage {
    width: 550px;
  }
}

@media (max-width: 960px) {
  .bannerContent {
    flex-direction: column;
  }

  .bannerDescription {
    margin: 46px 0 99px;
  }

  .projectDetailItems {
    flex-direction: row;
    justify-content: space-between;
    gap: revert;
    column-gap: 20px;

    margin-top: 46px;
  }

  .projectBannerImageContainer {
    justify-content: center;
  }

  .projectBannerImage {
    width: 80%;
  }
}

@media (max-width: 640px) {
  .bannerDescription {
    margin: 40px 0 26px;
  }

  .projectDetailItems {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: revert;
    column-gap: 16px;
    row-gap: 17px;

    width: 100%;
    margin-top: 40px;
  }

  .projectDetailItems > div {
    flex: 0 0 40%;
  }
}
