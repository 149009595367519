.container {
  margin-top: 130px;
}

.container h1 {
  margin-top: 0;
  margin-bottom: 24px;
}

.section {
  background: #f8f8f8;
  border-radius: 60px;
}

.sectionTitle {
  margin-top: 0;
  margin-bottom: 24px;
}

.sectionText {
  font-family: "Space Grotesk", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
}

/* SECTION 1*/
.section1 {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 72px;
  padding: 48px 60px 48px 48px;
}

.section1Description {
  display: flex;
  flex-direction: column;

  flex: 1;

  margin-left: 44px;
}

.section1Images {
  display: flex;
  flex-direction: row;

  flex: 2;
}

.section1Image {
  width: 50%;
}

/* SECTION 2 */
.section2 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 24px;

  margin-top: 48px;
}

.section2 > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  flex: 1;

  padding-top: 50px;
  padding-left: 60px;
  padding-right: 60px;
}

.section2Images {
  display: flex;
  justify-content: center;
}

.section2Image1 {
  width: 105%;
}

.section2Image2 {
  width: 80%;
}

/* SECTION 3 */
.section3 {
  display: flex;
  flex-direction: column;

  margin-top: 48px;

  padding: 50px 60px 50px;
}

.section3Images {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex: 1;

  margin-top: 50px;
}

.section3Image {
  width: 33%;
}

/* SECTION 4 */
.section4 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 24px;

  margin-top: 48px;
}

.section4 > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  flex: 1;

  padding-top: 50px;
  padding-left: 60px;
  padding-right: 60px;
}

.section4Images {
  display: flex;
  justify-content: center;
}

.section4Images > img {
  width: 82%;
}

.section4Image1 {
}

.section4Image2 {
}

/* SECTION 5 */
.section5 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 48px;
  padding: 58px 60px 58px 58px;
}

.section5Description {
  flex: 1;

  margin-left: 54px;
}

.section5Images {
  flex: 2;
}

.section5Image {
  width: 100%;
}

/* SECTION 6 */
.section6 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 24px;

  margin-top: 48px;
}

.section6 > div {
  display: flex;
  justify-content: space-between;
  flex: 1;
  gap: 24px;

  padding-left: 60px;
  padding-right: 60px;
}

.section6Subsection1 {
  flex-direction: column;
  padding-top: 50px;
}

.section6Subsection2 {
  flex-direction: column;
  padding-bottom: 50px;
}

.section6Images {
  display: flex;
  justify-content: center;
}

.section6Image1 {
  width: 82%;
}

.section6Image2 {
  width: 82%;
}

/* SECTION 7 */
.section7 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 48px;
  padding: 58px 58px 58px 60px;
}

.section7Description {
  flex: 1;

  margin-right: 54px;
}

.section7Images {
  flex: 2;
}

.section7Image {
  width: 100%;
}

/* SECTION 8 */
.section8 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 24px;

  margin-top: 48px;
}

.section8 > div {
  flex: 1;

  padding-left: 60px;
  padding-right: 60px;
}

.section8Subsection1 {
  padding-bottom: 50px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section8Subsection2 {
  padding-top: 50px;
}

.section8Images {
  display: flex;
  justify-content: center;
}

.section8Images > img {
  width: 82%;
}

.section8Image1 {
}

.section8Image2 {
  margin-top: 30px;
}

@media (max-width: 1280px) {
  .container {
    margin-top: 110px;
  }

  .container h1 {
    margin-bottom: 24px;
  }

  .sectionTitle {
    margin-bottom: 24px;
  }

  .sectionText {
    font-size: 16px;
    line-height: 23px;
  }

  /* SECTION 1 */
  .section1 {
    margin-top: 56px;
    padding: 32px 42px 32px 32px;
  }

  .section1Description {
    margin-left: 32px;
  }

  /* SECTION 2 */
  .section2 {
    gap: 24px;

    margin-top: 42px;
  }

  .section2 > div {
    gap: 27px;

    padding-top: 34px;
    padding-left: 38px;
    padding-right: 38px;
  }

  .section2Image1 {
    width: 100%;
  }

  .section2Image2 {
    width: 80%;
  }

  /* SECTION 3 */
  .section3 {
    margin-top: 42px;

    padding: 34px 38px 51px;
  }

  .section3Images {
    margin-top: 30px;
  }

  .section3Image {
    width: 32%;
  }

  /* SECTION 4 */
  .section4 {
    gap: 24px;

    margin-top: 42px;
  }

  .section4 > div {
    gap: 27px;

    padding-top: 34px;
    padding-left: 38px;
    padding-right: 38px;
  }

  .section4Images > img {
    width: 79%;
  }

  .section4Image1 {
  }

  .section4Image2 {
  }

  /* SECTION 5 */
  .section5 {
    margin-top: 42px;
    padding: 40px;
  }

  .section5Description {
    margin-left: 42px;
  }

  /* SECTION 6 */
  .section6 {
    gap: 24px;

    margin-top: 42px;
  }

  .section6 > div {
    gap: 30px;

    padding-left: 38px;
    padding-right: 38px;
  }

  .section6Subsection1 {
    padding-top: 34px;
  }

  .section6Subsection2 {
    padding-bottom: 38px;
  }

  .section6Image1 {
    width: 79%;
  }

  .section6Image2 {
    width: 79%;
  }

  /* SECTION 7 */
  .section7 {
    gap: 46px;

    padding: 40px 38px 40px;

    margin-top: 42px;
  }

  .section7Description {
    margin-right: revert;
  }

  /* SECTION 8 */
  .section8 {
    gap: 24px;

    margin-top: 42px;
  }

  .section8 > div {
    padding-left: 38px;
    padding-right: 38px;
  }

  .section8Subsection1 {
    padding-bottom: 38px;
  }

  .section8Subsection2 {
    padding-top: 38px;
  }

  .section8Image1 {
    width: 79%;
  }

  .section8Image2 {
    width: 79%;
  }
}

@media (max-width: 960px) {
  .container {
    margin-top: 96px;
  }

  .container h1 {
    margin-bottom: 20px;
  }

  .sectionTitle {
    margin-bottom: 20px;
  }

  .sectionText {
    font-size: 16px;
    line-height: 23px;
  }

  /* SECTION 1 */
  .section1 {
    flex-direction: column;
    margin-top: 46px;
    padding: 48px;
  }

  .section1Description {
    margin-left: revert;
    margin-top: 38px;
  }

  /* SECTION 2 */
  .section2 {
    flex-direction: column;
    gap: 36px;

    margin-top: 36px;
  }

  .section2 > div {
    gap: 36px;

    padding-top: 48px;
    padding-left: 48px;
    padding-right: 48px;
  }

  .section2Image1 {
    width: 90%;
  }

  .section2Image2 {
    width: 55%;
  }

  /* SECTION 3 */
  .section3 {
    margin-top: 36px;

    padding: 38px 48px 36px;
  }

  .section3Images {
    margin-top: 36px;
  }

  .section3Images :nth-child(1) {

  }

  .section3Images :nth-child(2) {
    margin-left: -15%;
  }

  .section3Images :nth-child(3) {
    margin-left: -15%;
  }

  .section3Image {
    width: 45%;
  }

  /* SECTION 4 */
  .section4 {
    flex-direction: column;
    gap: 36px;

    margin-top: 36px;
  }

  .section4 > div {
    gap: 36px;

    padding-top: 38px;
    padding-left: 48px;
    padding-right: 48px;
  }

  .section4Images > img {
    width: 55%;
  }

  .section4Image1 {
  }

  .section4Image2 {
  }

  /* SECTION 5 */
  .section5 {
    flex-direction: column-reverse;
    gap: 36px;

    margin-top: 36px;
    padding: 38px 48px 48px;
  }

  .section5Description {
    flex: unset;
    margin-left: revert;
  }

  .section5Images {
    display: flex;
    justify-content: center;
  }

  .section5Image {
    width: 90%;
  }

  /* SECTION 6 */
  .section6 {
    flex-direction: column;
    gap: 36px;

    margin-top: 36px;
  }

  .section6 > div {
    gap: 36px;

    padding-top: 38px;
    padding-left: 48px;
    padding-right: 48px;
  }

  .section6Subsection1 {

  }

  .section6Subsection2 {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 38px;
  }

  .section6Images {

  }

  .section6Image1 {
    width: 55%;
  }

  .section6Image2 {
    width: 55%;
  }

  /* SECTION 7 */
  .section7 {
    flex-direction: column;
    gap: 36px;

    padding: 38px 48px 48px;

    margin-top: 36px;
  }

  .section7Description {
    margin-right: revert;
  }

  .section7Images {
    display: flex;
    justify-content: center;
  }

  .section7Image {
    width: 90%;
  }

  /* SECTION 8 */
  .section8 {
    flex-direction: column;
    gap: 36px;

    margin-top: 36px;
  }

  .section8 > div {
    padding: 38px 48px;
  }

  .section8Subsection1 {
    display: flex;
    flex-direction: column-reverse;

    padding-bottom: 46px;
  }

  .section8Subsection2 {
    padding-top: 46px;
  }

  .section8Images {
    margin-top: 36px;
  }

  .section8Images > img {
    width: 55%;
  }

  .section8Image1 {
  }

  .section8Image2 {
  }
}

@media (max-width: 640px) {
  .container {
    margin-top: 64px;
  }

  .section {
    border-radius: 28px;
  }

  .container h1 {
    margin-bottom: 14px;
  }

  .sectionTitle {
    margin-bottom: 14px;
    border-radius: 42px;
  }

  .sectionText {
    font-size: 14px;
    line-height: 19px;
  }

  /* SECTION 1 */
  .section1 {
    flex-direction: column;
    margin-top: 34px;
    padding: 16px 22px 26px;
  }

  .section1Description {
    margin-left: revert;
    margin-top: 22px;
  }

  /* SECTION 2 */
  .section2 {
    gap: 30px;

    margin-top: 30px;
  }

  .section2 > div {
    gap: 22px;

    padding-top: 22px;
    padding-left: 22px;
    padding-right: 22px;
  }

  .section2Image1 {
  }

  .section2Image2 {
    width: 80%;
  }

  /* SECTION 3 */
  .section3 {
    margin-top: 30px;
    margin-bottom: 30px;

    padding: 22px;
  }

  .section3Images {
    flex-direction: column;
    margin-top: 22px;
  }

  .section3Images > img {
    align-self: center;
    width: 80%;
    flex: revert;
  }

  .section3Images :nth-child(1) {
    margin-left: initial;
  }

  .section3Images :nth-child(2) {
    margin-left: initial;
  }

  .section3Images :nth-child(3) {
    margin-left: initial;
  }

  .section3Image {
    width: 30%;
  }

  /* SECTION 4 */
  .section4 {
    gap: 30px;

    margin-top: 30px;
  }

  .section4 > div {
    gap: 22px;

    padding-top: 22px;
    padding-left: 22px;
    padding-right: 22px;
  }

  .section4Images > img {
    width: 80%;
  }

  .section4Image1 {
  }

  .section4Image2 {
  }

  /* SECTION 5 */
  .section5 {
    flex-direction: column-reverse;
    gap: 22px;

    margin-top: 30px;
    padding: 22px;
  }

  .section5Description {
    margin-left: revert;
  }

  .section5Image {
    width: 100%;
  }

  /* SECTION 6 */
  .section6 {
    gap: 30px;

    margin-top: 30px;
  }

  .section6 > div {
    gap: 22px;

    padding-top: 22px;
    padding-left: 22px;
    padding-right: 22px;
  }

  .section6Subsection2 {
    flex-direction: column-reverse;

    padding-bottom: 16px;
  }

  .section6Image1 {
    width: 80%;
  }

  .section6Image2 {
    width: 80%;
  }

  /* SECTION 7 */
  .section7 {
    flex-direction: column;
    gap: 22px;

    padding: 22px;

    margin-top: 30px;
  }

  .section7Description {
    margin-right: revert;
  }

  .section7Image {
    width: 100%;
  }

  /* SECTION 8 */
  .section8 {
    flex-direction: column;
    gap: 30px;

    margin-top: 30px;
  }

  .section8 > div {
    padding: 22px 22px 16px;
  }

  .section8Subsection1 {
    display: flex;
    flex-direction: column-reverse;

    padding-bottom: 46px;
  }

  .section8Subsection2 {
  }

  .section8Images {
    margin-top: 22px;
  }

  .section8Images > img {
    width: 80%;
  }

  .section8Image1 {
  }

  .section8Image2 {
  }
}