.sectionContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.section {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-start;

  width: 1200px;

  margin-left: 40px;
  margin-right: 40px;
}

@media (max-width: 1280px) {
  .section {
    width: 1000px;
  }
}

@media (max-width: 960px) {
  .section {
    width: 1000px;

    margin-left: 38px;
    margin-right: 38px;
  }
}

@media (max-width: 640px) {
  .sectionContainer {
    width: 100%;
  }

  .section {
    width: 100%;

    margin-left: 16px;
    margin-right: 16px;
  }
}
