.container h1 {
  margin-top: 0;
  margin-bottom: 24px;
}

.section {
  background: #F7F8FC;
  border-radius: 60px;
}

.sectionTitle {
  color: #151C45;

  margin-top: 0;
  margin-bottom: 24px;
}

.sectionText {
  font-family: "Space Grotesk", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #151C45;
}

/* SECTION 1*/
.section1 {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 52px;
  padding: 50px 60px 36px;
}

.section1Description {
  display: flex;
  flex-direction: column;
}

.section1Images {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  margin-top: 36px;
}

.section1Image {
  width: 35%;
}

/* SECTION 2 */
.section2 {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 48px;
  padding: 50px 60px 36px;
}

.section2Images {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  margin-top: 36px;
}

.section2Image {
  width: 35%;
}

/* SECTION 3 */
.section3 {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 48px;
  padding: 50px 60px 36px;
}

.section3Images {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;

  margin-top: 36px;
}

.section3Image {
  width: 33%;
}

/* SECTION 4 */
.section4 {
  display: flex;
  flex-direction: row;
  gap: 24px;

  margin-top: 48px;
}

.section4 > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 28px;

  flex: 1;

  padding: 50px 60px 36px;
}

.section4Description {

}

.section4Images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section4Image {
  width: 80%;
}

/* SECTION 5 */
.section5 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 36px;

  margin-top: 48px;
  padding: 36px 36px 36px 60px;
}

.section5Description {
  flex: 1;
}

.section5Images {
  flex: 2;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section5Image {
  width: 50%;
}

/* SECTION 6 */
.section6 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 36px;

  margin-top: 48px;
  padding: 36px 60px 36px 36px;
}

.section6Description {
  flex: 1;
}

.section6Images {
  flex: 2;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section6Image {
  width: 50%;
}

/* SECTION 7 */
.section7 {
  display: flex;
  flex-direction: row;
  gap: 24px;

  margin-top: 48px;
}

.section7 > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;

  flex: 1;

  padding: 50px 60px 36px;
}

.section7Description {

}

.section7Images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section7Image {
  width: 80%;
}

/* SECTION 8 */
.section8 {
  display: flex;
  flex-direction: row;
  gap: 24px;

  margin-top: 48px;
}

.section8 > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;

  flex: 1;

  padding: 50px 60px 36px;
}

.section8Description {

}

.section8Images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section8Image {
  width: 80%;
}

/* SECTION 9 */
.section9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  margin-top: 48px;
  padding: 50px 60px 36px 60px;
}

.section9Description {
}

.section9Images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 19px;
}

.section9Image {
  width: 40%;
}

/* SECTION 10 */
.section10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  margin-top: 48px;
  padding: 50px 60px 36px 60px;
}

.section10Description {
}

.section10Images {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.section10Image {
  width: 35%;
}

@media (max-width: 1280px) {
  .container h1 {
    margin-bottom: 24px;
  }

  .sectionTitle {
    margin-bottom: 24px;
  }

  .sectionText {
    font-size: 16px;
    line-height: 23px;
  }

  /* SECTION 1 */
  .section1 {
    margin-top: 46px;
    padding: 34px 38px 51px;
  }

  .section1Images {
    margin-top: 40px;
  }

  .section1Image {
    width: 33%;
  }

  /* SECTION 2 */
  .section2 {
    margin-top: 42px;
    padding: 34px 38px 51px;
  }

  .section2Images {
    margin-top: 40px;
  }

  .section2Image {
    width: 33%;
  }

  /* SECTION 3 */
  .section3 {
    margin-top: 42px;
    padding: 34px 38px 51px;
  }

  .section3Images {
    justify-content: center;
    margin-top: 40px;
  }

  .section3Image {
    width: 33%;
  }

  /* SECTION 4 */
  .section4 {
    margin-top: 42px;
  }

  .section4 > div {
    padding: 34px 38px;
  }

  .section4Description > h3 {
    width: revert;
  }

  .section4Image {
    width: 80%;
  }

  /* SECTION 5 */
  .section5 {
    margin-top: 42px;
    padding: 32px 38px;
  }

  .section5Image {
    width: 51%;
  }

  /* SECTION 6 */
  .section6 {
    margin-top: 36px;
    padding: 38px 48px 48px;
  }

  .section6Image {
    width: 51%;
  }

  /* SECTION 7 */
  .section7 {
    margin-top: 42px;
  }

  .section7 > div {
    padding: 34px 38px 40px;
  }

  .section7Images {
    margin-top: 40px;
  }

  /* SECTION 8 */
  .section8 {
    margin-top: 42px;
  }

  .section8 > div {
    padding: 34px 38px 40px;
  }

  .section8Images {
    margin-top: 40px;
  }

  .section8Image {
    width: 80%;
  }

  /* SECTION 9 */
  .section9 {
    margin-top: 42px;
    padding: 34px 38px 40px;

    gap: 40px;
  }

  .section9Images {
    column-gap: revert;
    row-gap: revert;
  }

  .section9Image {
    width: 40%;
  }

  /* SECTION 10 */
  .section10 {
    margin-top: 42px;
    padding: 34px 38px 51px;

    gap: 40px;
  }

  .section10Image {
    width: 33%;
  }
}

@media (max-width: 960px) {
  .container h1 {
    margin-bottom: 20px;
  }

  .sectionTitle {
    margin-bottom: 20px;
  }

  .sectionText {
    font-size: 16px;
    line-height: 23px;
  }

  /* SECTION 1 */
  .section1 {
    margin-top: 46px;
    padding: 38px 48px 36px;
  }

  .section1Images {
    justify-content: space-around;

    margin-top: 36px;
  }

  .section1Images :nth-child(1) {

  }

  .section1Images :nth-child(2) {
    margin-left: -3%;
  }

  .section1Images :nth-child(3) {
    margin-left: -3%;
  }

  .section1Image {
    width: 37%;
  }

  /* SECTION 2 */
  .section2 {
    margin-top: 46px;
    padding: 38px 48px 36px;
  }

  .section2Images {
    justify-content: space-around;

    margin-top: 36px;
  }

  .section2Images :nth-child(1) {

  }

  .section2Images :nth-child(2) {
    margin-left: -3%;
  }

  .section2Images :nth-child(3) {
    margin-left: -3%;
  }

  .section2Image {
    width: 37%;
  }

  /* SECTION 3 */
  .section3 {
    margin-top: 46px;
    padding: 38px 48px 30px;
  }

  .section3Images {
    margin-top: 36px;
  }

  .section3Image {
    width: 50%;
  }

  /* SECTION 4 */
  .section4 {
    flex-direction: column;
    gap: 36px;

    margin-top: 36px;
  }

  .section4 > div {
    gap: 36px;

    padding: 38px 48px;
  }

  .section4Description > h3 {
    width: revert;
  }

  .section4Image {
    width: 80%;
  }

  /* SECTION 5 */
  .section5 {
    flex-direction: column;

    margin-top: 36px;
    padding: 38px 48px 48px;
  }

  /* SECTION 6 */
  .section6 {
    flex-direction: column-reverse;

    margin-top: 36px;
    padding: 38px 48px 48px;
  }

  /* SECTION 7 */
  .section7 {
    flex-direction: column;
    gap: 36px;

    margin-top: 36px;
  }

  .section7 > div {
    gap: 36px;

    padding-top: 38px;
    padding-left: 48px;
    padding-right: 48px;
  }

  .section7Images {
    margin-top: revert;
  }

  .section7Image {
    width: 80%;
  }

  /* SECTION 8 */
  .section8 {
    flex-direction: column;
    gap: 36px;

    margin-top: 36px;
  }

  .section8 > div {
    padding-top: 38px;
    padding-left: 48px;
    padding-right: 48px;

    gap: 36px;
  }

  .section8Images {
    margin-top: revert;
  }

  .section8Image {
    width: 80%;
  }

  /* SECTION 9 */
  .section9 {
    margin-top: 46px;
    padding: 38px 48px 36px;

    gap: 36px;
  }

  .section9Images {
    column-gap: revert;
    row-gap: revert;
  }

  .section9Image {
    width: 50%;
  }

  /* SECTION 10 */
  .section10 {
    gap: 36px;

    margin-top: 46px;
    padding: 38px 48px 36px;
  }

  .section10Images {
    justify-content: space-around;
  }

  .section10Images :nth-child(1) {

  }

  .section10Images :nth-child(2) {
    margin-left: -3%;
  }

  .section10Images :nth-child(3) {
    margin-left: -3%;
  }

  .section10Image {
    width: 37%;
  }
}

@media (max-width: 640px) {
  .container h1 {
    margin-bottom: 14px;
  }

  .section {
    border-radius: 42px;
  }

  .sectionTitle {
    margin-bottom: 14px;
  }

  .sectionText {
    font-size: 14px;
    line-height: 19px;
  }

  /* SECTION 1 */
  .section1 {
    margin-top: 34px;
    padding: 22px;
  }

  .section1Images {
    flex-direction: column;
    align-items: center;

    margin-top: 22px;
  }

  .section1Images :nth-child(2) {
    margin-left: initial;
  }

  .section1Images :nth-child(3) {
    margin-left: initial;
  }

  .section1Image {
    width: 80%;
  }

  /* SECTION 2 */
  .section2 {
    margin-top: 34px;
    padding: 22px;
  }

  .section2Images {
    flex-direction: column;
    align-items: center;

    margin-top: 22px;
  }

  .section2Image {
    width: 80%;
  }

  /* SECTION 3 */
  .section3 {
    margin-top: 34px;
    padding: 22px;
  }

  .section3Images {
    align-items: center;

    margin-top: 22px;
  }

  .section3Image {
    width: 50%;
  }

  /* SECTION 4 */
  .section4 {
    flex-direction: column;
    gap: 30px;

    height: revert;

    margin-top: 30px;
  }

  .section4 > div {
    gap: 22px;

    padding: 22px;
  }

  .section4Description > h3 {
    width: revert;
  }

  /* SECTION 5 */
  .section5 {
    flex-direction: column;
    gap: 22px;

    margin-top: 30px;
    padding: 22px;
  }

  /* SECTION 6 */
  .section6 {
    flex-direction: column-reverse;
    gap: 22px;

    margin-top: 30px;
    padding: 22px;
  }

  /* SECTION 7 */
  .section7 {
    flex-direction: column;
    gap: 30px;

    height: revert;

    margin-top: 30px;
  }

  .section7 > div {
    gap: 22px;

    padding: 22px;
  }

  /* SECTION 8 */
  .section8 {
    flex-direction: column;
    gap: 30px;

    height: revert;

    margin-top: 30px;
  }

  .section8 > div {
    gap: 22px;

    padding: 22px;
  }

  /* SECTION 9 */
  .section9 {
    gap: 22px;

    margin-top: 30px;
    padding: 22px;
  }

  .section9Image {
    width: 50%;
  }

  /* SECTION 10 */
  .section10 {
    gap: 22px;

    margin-top: 30px;
    padding: 22px;
  }

  .section10Images {
    flex-direction: column;
    align-items: center;
  }

  .section10Image {
    width: 80%;
  }
}