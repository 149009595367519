.aboutMeContainer {
  display: flex;
  flex-direction: row;
  gap: 61px;

  margin-top: 112px;
}

.aboutMe {
  flex: 3;

  display: flex;
  flex-direction: column;
}

.aboutMe :nth-child(2) {
  margin-top: 36px;
}

.aboutMeImageContainer {
  flex: 2;
}

.aboutMeImage {
  border: 2px solid #000000;
  height: 537px;

  margin-top: 23px;
}

@media (max-width: 1280px) {
  .aboutMe {
    flex: 4;
  }

  .aboutMeContainer {
    gap: 57px;
    margin-top: 52px;
  }

  .aboutMe :nth-child(2) {
    margin-top: 30px;
  }

  .aboutMeImage {
    height: 475px;

    margin-top: 25px;
  }
}

@media (max-width: 960px) {
  .aboutMeContainer {
    flex-direction: column-reverse;
    gap: 70px;
    margin-top: 22px;
  }

  .aboutMe :nth-child(2) {
    margin-top: 26px;
  }

  .aboutMeImage {
    border: 1.5px solid #000000;
    height: 382px;
  }
}

@media (max-width: 640px) {
  .aboutMeContainer {
    flex-direction: column-reverse;
    gap: 64px;
    margin-top: 20px;
  }

  .aboutMe :nth-child(2) {
    margin-top: 24px;
  }

  .aboutMeImageContainer {
    flex: 100%;
  }

  .aboutMeImage {
    border: 1.2px solid #000000;
    height: 180px;
  }
}