.bannerBackground {
  background: rgb(255, 224, 59);
}

.bannerContent {
  display: flex;
  width: 100%;
}

.bannerDescription {
  display: flex;
  flex-direction: column;
  flex: 2;
  margin: 78px 0;
}

.projectDetailItems {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 48px;
}

.projectDetailItems > div {
  width: 352px;
}

.projectBannerImageContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 3;
}

.projectBannerImage {
  width: 680px;
}

@media (max-width: 1280px) {
  .bannerDescription {
    margin: 58px 0;
  }

  .projectDetailItems {
    gap: 32px;
    margin-top: 46px;
  }

  .projectBannerImageContainer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .projectBannerImage {
    width: 103%;
  }
}

@media (max-width: 960px) {
  .bannerContent {
    flex-direction: column;
  }

  .bannerDescription {
    margin: 46px 0 43px;
  }

  .projectDetailItems {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 62px;
    row-gap: 38px;
    margin-top: 46px;
  }

  .projectDetailItems > div {
    flex: 40%;
  }

  .projectDetailItems :nth-child(1) {
    order: 1;
  }

  .projectDetailItems :nth-child(2) {
    order: 3;
  }

  .projectDetailItems :nth-child(3) {
    order: 2;
  }

  .projectDetailItems :nth-child(4) {
    order: 4;
  }

  .projectBannerImageContainer {
    align-items: unset;
    justify-content: center;
  }

  .projectBannerImage {
    width: 80%;
  }
}

@media (max-width: 640px) {
  .bannerDescription {
    margin: 40px 0 36px;
  }

  .projectDetailItems {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 17px;

    width: 100%;
    margin-top: 40px;
  }

  .projectDetailItems > div {
    flex: 47%;
  }

  .projectDetailItems :nth-child(1) {
    order: 1;
  }

  .projectDetailItems :nth-child(2) {
    order: 3;
  }

  .projectDetailItems :nth-child(2) > span {
    width: 136px;
  }

  .projectDetailItems :nth-child(3) {
    order: 2;
  }

  .projectDetailItems :nth-child(4) {
    order: 4;
  }
}
