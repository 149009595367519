.header {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
}

.menuButton {
  display: none;
  align-self: center;
}

.headerLinks {
  display: flex;
  gap: 46px;
}

.headerLogoLink {
  color: black;
  text-decoration: none;
}

.headerLink {
  color: black;
  text-decoration: none;
}

.headerSelectedLink {
  font-weight: bold;
  text-decoration: underline;
}

@media (max-width: 1280px) {
  .headerLinks {
    gap: 42px;
  }
}

@media (max-width: 960px) {
  .headerLinks {
    gap: 32px;
  }
}

@media (max-width: 640px) {
  .header {
    align-items: center;
    flex-wrap: wrap;
    margin-top: 22px;
    margin-bottom: 22px;
  }

  .menuButton {
    display: flex;
    z-index: 11;
  }

  .headerLinks {
    flex: 100%;
    gap: 18px;

    flex-direction: column;
    align-items: flex-start;

    background-color: white;

    height: 50%;
    width: 100%;

    margin-left: -16px;
    padding-left: 16px;
    padding-top: 164px;

    z-index: 10;

    position: absolute;
    top: -1000px;
    transition: all 1s ease-in-out;
  }

  .headerLinksOnMobileOpened {
    height: 100%;
    top: 0;
    animation: fadeIn 1s ease-in-out forwards;
  }

  .headerLink {
    font-family: 'Alexandria';
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;

    color: black;
  }

  .headerSelectedLink {
    text-decoration: unset;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
}