.container {
  display: flex;
  flex-direction: column;
  gap: 15px;

  margin-top: 112px;
}

@media (max-width: 1280px) {
  .container {
    margin-top: 76px;
    gap: 8px;
  }
}

@media (max-width: 640px) {
  .container {
    margin-top: 28px;
    gap: 14px;
  }
}