body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.big-title {
  font-family: "Alexandria";
  font-style: normal;
  font-weight: 700;
  font-size: 92px;
  line-height: 102px;
}

.title {
  font-family: "Alexandria";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
}

.section-title-small {
  font-family: "Space Grotesk", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;

  letter-spacing: 0.02em;
}

.text {
  font-family: "Space Grotesk", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  line-height: 33px;
}

.project-banner-title {
  font-family: "Alexandria", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  letter-spacing: 0.02em;
}

@media (max-width: 1280px) {
  .big-title {
    font-size: 76px;
    line-height: 84px;
  }

  .section-title-small {
    font-size: 32px;
    line-height: 38px;

    letter-spacing: 0.02em;
  }

  .title {
    font-size: 42px;
    line-height: 50px;
  }

  .text {
    font-size: 20px;
    line-height: 30px;
  }

  .project-banner-title {
    font-size: 56px;
    line-height: 68px;
  }
}

@media (max-width: 960px) {
  .big-title {
    font-size: 56px;
    line-height: 59px;
  }

  .section-title-small {
    font-size: 28px;
    line-height: 37px;

    letter-spacing: 0.03em;
  }

  .title {
    font-size: 36px;
    line-height: 46px;
  }

  .text {
    font-size: 18px;
    line-height: 28px;
  }

  .project-banner-title {
    font-size: 56px;
    line-height: 68px;
  }
}

@media (max-width: 640px) {
  .big-title {
    font-size: 48px;
    line-height: 52px;
  }

  .title {
    font-size: 30px;
    line-height: 34px;
  }

  .section-title-small {
    font-size: 24px;
    line-height: 30px;

    letter-spacing: 0.01em;
  }

  .text {
    font-size: 16px;
    line-height: 22px;
  }

  .project-banner-title {
    font-size: 38px;
    line-height: 44px;
  }
}
