.container {
  margin-top: 112px;
}

.title {
  margin-top: 0;
  margin-bottom: 36px;
}

.year2022 {
  margin-top: 132px;
  margin-bottom: 4px;
}

.uklon {
  margin-top: 28px;
}

.eventzone {
  margin-top: 56px;
}

@media (max-width: 1280px) {
  .container {
    margin-top: 52px;
  }

  .title {
    margin-bottom: 30px;
  }

  .year2022 {
    margin-top: 114px;
  }

  .eventzone {
    margin-top: 46px;
  }
}

@media (max-width: 960px) {
  .container {
    margin-top: 58px;
  }

  .title {
    margin-bottom: 26px;
  }

  .year2022 {
    margin-top: 90px;
  }

  .uklon {
    margin-top: 20px;
  }

  .eventzone {
    margin-top: 38px;
  }
}

@media (max-width: 767px) {
  .eventzone {
    margin-top: 120px;
  }
}

@media (max-width: 640px) {
  .container {
    margin-top: 18px;
  }

  .title {
    margin-bottom: 20px;
  }

  .year2022 {
    margin-top: 100px;
  }

  .uklon {
    margin-top: 14px;
  }

  .eventzone {
    margin-top: 100px;
  }
}