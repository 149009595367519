.projectPreview {
  display: flex;
  align-items: center;

  height: 630px;
  width: 100%;
}

.projectPreviewImage {
  height: 100%;
}

.projectPreviewDescription {
  display: flex;
  flex-direction: column;

  padding-left: 47px;
  padding-right: 75px;
}

.projectPreviewDescription :nth-child(2) {
  margin-top: 22px;
}

.projectPreviewDescription :nth-child(3) {
  margin-top: 91px;
}

.ellipseButton {
  display: inline-block;
  width: 172px;
  padding: 16px 25px;
  color: #000000;
  border: 2px solid #000000;
  border-radius: 40px;
  text-align: center;
  outline: none;
  text-decoration: none;
  transition: 0.7s;
}

@media (max-width: 1280px) {
  .projectPreview {
    height: 496px;
  }

  .projectPreviewDescription {
    padding-left: 24px;
    padding-right: 34px;
  }

  .projectPreviewDescription :nth-child(2) {
    margin-top: 21px;
  }

  .projectPreviewDescription :nth-child(3) {
    margin-top: 67px;
  }

  .ellipseButton {
    width: 206px;
    padding: 13px 8px;
    border: 1.5px solid #000000;
    border-radius: 32px;
  }
}

@media (max-width: 960px) {
  .projectPreview {
    height: 50vw;
  }

  .projectPreviewDescription {
    padding-left: 18px;
    padding-right: 22px;
  }

  .projectPreviewDescription :nth-child(2) {
    margin-top: 12px;
  }

  .projectPreviewDescription :nth-child(3) {
    margin-top: 36px;
  }

  .ellipseButton {
    width: 146px;
    padding: 9px 12px;
    border: 1.5px solid #000000;
    border-radius: 32px;
  }
}

@media (max-width: 767px) {
  .projectPreview {
    flex-direction: column;
    height: initial;
  }

  .projectPreviewDescription {
    align-items: flex-start;
    padding-left: 0;
    padding-right: 0;
    padding-top: 24px;

    background-color: white;
  }

  .projectPreviewDescription :nth-child(2) {
    margin-top: 12px;
  }

  .projectPreviewDescription :nth-child(3) {
    margin-top: 38px;
  }

  .projectPreviewImage {
    height: initial;
    width: 100%;
  }

  .ellipseButton {
    width: 146px;
    padding: 9px 12px;
    border: 1.5px solid #000000;
    border-radius: 32px;
  }
}

@media (max-width: 640px) {
  .projectPreview {
    flex-direction: column;
    height: initial;
  }

  .projectPreviewDescription {
    align-items: flex-start;
    padding-left: 0;
    padding-right: 0;
    padding-top: 24px;

    background-color: white;
  }

  .projectPreviewDescription :nth-child(2) {
    margin-top: 14px;
  }

  .projectPreviewDescription :nth-child(3) {
    margin-top: 38px;
  }

  .projectPreviewImage {
    height: initial;
    width: 100%;
  }

  .ellipseButton {
    width: 140px;
    padding: 12px 15px;
    border: 1.2px solid #000000;
  }
}