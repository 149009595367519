.container {
  display: inline-block;
  cursor: pointer;

  padding: 12px;
  margin: -12px;
}

.container :nth-child(1) {
  margin-bottom: 8px;
}

.container :nth-child(2) {
  margin-top: 8px;
}

.line1, .line2 {
  width: 24px;
  height: 2px;
  background-color: #000000;
  transition: 0.8s;
}

.toggledLine1 {
  transform: translate(0px, 4px) rotate(-45deg);
}

.toggledLine2 {
  transform: translate(0px, -6px) rotate(45deg);
}